import React from "react";

export default function Nice(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d="M20 0C8.97201 0 0 8.97201 0 20C0 31.028 8.97201 40 20 40C31.028 40 40 31.028 40 20C40 8.97201 31.028 0 20 0ZM20 37.8378C15.1003 37.8378 10.6562 35.8518 7.42929 32.6431C6.14062 31.3618 5.04669 29.885 4.19492 28.262C2.89761 25.7905 2.16219 22.9796 2.16219 20C2.16219 10.1642 10.1642 2.16219 20 2.16219C24.6653 2.16219 28.9173 3.9633 32.0992 6.90577C33.749 8.4312 35.1113 10.2631 36.0936 12.3107C37.2112 14.6403 37.8378 17.2483 37.8378 20C37.8378 29.8358 29.8358 37.8378 20 37.8378Z"
        fill={props.fill}
      />
      <path
        d="M32.4323 20H30.2701C30.2701 25.6758 25.6629 30.2701 20 30.2701C14.337 30.2701 9.72982 25.6758 9.72982 20H7.56763C7.56763 26.892 13.1449 32.4323 20 32.4323C26.8552 32.4323 32.4323 26.892 32.4323 20Z"
        fill={props.fill}
      />
      <path
        d="M13.3335 17.7028C14.5276 17.7028 15.4956 16.7348 15.4956 15.5406C15.4956 14.3465 14.5276 13.3784 13.3335 13.3784C12.1393 13.3784 11.1713 14.3465 11.1713 15.5406C11.1713 16.7348 12.1393 17.7028 13.3335 17.7028Z"
        fill={props.fill}
      />
      <path
        d="M26.712 17.7028C27.9061 17.7028 28.8742 16.7348 28.8742 15.5406C28.8742 14.3465 27.9061 13.3784 26.712 13.3784C25.5179 13.3784 24.5498 14.3465 24.5498 15.5406C24.5498 16.7348 25.5179 17.7028 26.712 17.7028Z"
        fill={props.fill}
      />
    </svg>
  );
}
