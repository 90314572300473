import React from "react";

export default function VerySad(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d="M20 0C8.97201 0 0 8.97201 0 20C0 31.028 8.97201 40 20 40C31.028 40 40 31.028 40 20C40 8.97201 31.0281 0 20 0ZM20 37.8378C15.1005 37.8378 10.6562 35.8518 7.42929 32.6431C6.14062 31.3618 5.04669 29.885 4.19492 28.262C2.89761 25.7905 2.16219 22.9798 2.16219 20C2.16219 10.1642 10.1642 2.16219 20 2.16219C24.6653 2.16219 28.9173 3.9633 32.0992 6.90577C33.749 8.4312 35.1113 10.2631 36.0936 12.3107C37.2112 14.6403 37.8378 17.2483 37.8378 20C37.8378 29.8358 29.8358 37.8378 20 37.8378Z"
        fill={props.fill}
      />
      <path
        d="M14.4608 19.9814L14.4734 19.994L18.4266 16.0406L14.4733 12.0874L12.9445 13.6165L15.369 16.0406L13.4795 17.9299L12.9443 18.4651L14.4608 19.9814Z"
        fill={props.fill}
      />
      <path
        d="M26.5202 17.9299L24.6309 16.0406L27.0552 13.6165L25.5266 12.0874L21.5731 16.0406L25.5264 19.994L25.5388 19.9814L27.0554 18.4651L26.5202 17.9299Z"
        fill={props.fill}
      />
      <path
        d="M28.8738 25.5859V25.5401C28.8738 25.142 28.827 24.7582 28.6998 24.4009C28.2657 23.1801 27.1671 22.2717 25.8322 22.1722C25.7516 22.1662 25.6818 22.1621 25.5997 22.1621H14.4C14.318 22.1621 14.2368 22.1662 14.1562 22.1722C12.8212 22.2717 11.6889 23.1801 11.2548 24.4009C11.1278 24.7581 11.0358 25.1419 11.0358 25.5401V25.5859C11.0358 27.4266 12.5592 28.919 14.4 28.919H25.5997C27.4404 28.919 28.8738 27.4266 28.8738 25.5859ZM13.198 25.5859V25.5401C13.198 24.8946 13.7543 24.3243 14.4 24.3243H25.5404H25.5996C26.2452 24.3243 26.7117 24.8946 26.7117 25.5401V25.5859C26.7117 26.2315 26.2453 26.7568 25.5996 26.7568H14.3999C13.7543 26.7568 13.198 26.2315 13.198 25.5859Z"
        fill={props.fill}
      />
    </svg>
  );
}
