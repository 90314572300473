export const currencyCodes = [
  { label: "AFA", value: "AFA" },
  { label: "ALL", value: "ALL" },
  { label: "DZD", value: "DZD" },
  { label: "AOA", value: "AOA" },
  { label: "ARS", value: "ARS" },
  { label: "AMD", value: "AMD" },
  { label: "AWG", value: "AWG" },
  { label: "AUD", value: "AUD" },
  { label: "AZN", value: "AZN" },
  { label: "BSD", value: "BSD" },
  { label: "BHD", value: "BHD" },
  { label: "BDT", value: "BDT" },
  { label: "BBD", value: "BBD" },
  { label: "BYR", value: "BYR" },
  { label: "BEF", value: "BEF" },
  { label: "BZD", value: "BZD" },
  { label: "BMD", value: "BMD" },
  { label: "BTN", value: "BTN" },
  { label: "BTC", value: "BTC" },
  { label: "BOB", value: "BOB" },
  { label: "BAM", value: "BAM" },
  { label: "BWP", value: "BWP" },
  { label: "BRL", value: "BRL" },
  { label: "GBP", value: "GBP" },
  { label: "BND", value: "BND" },
  { label: "BGN", value: "BGN" },
  { label: "BIF", value: "BIF" },
  { label: "KHR", value: "KHR" },
  { label: "CAD", value: "CAD" },
  { label: "CVE", value: "CVE" },
  { label: "KYD", value: "KYD" },
  { label: "XOF", value: "XOF" },
  { label: "XAF", value: "XAF" },
  { label: "XPF", value: "XPF" },
  { label: "CLP", value: "CLP" },
  { label: "CNY", value: "CNY" },
  { label: "COP", value: "COP" },
  { label: "KMF", value: "KMF" },
  { label: "CDF", value: "CDF" },
  { label: "CRC", value: "CRC" },
  { label: "HRK", value: "HRK" },
  { label: "CUC", value: "CUC" },
  { label: "CZK", value: "CZK" },
  { label: "DKK", value: "DKK" },
  { label: "DJF", value: "DJF" },
  { label: "DOP", value: "DOP" },
  { label: "XCD", value: "XCD" },
  { label: "EGP", value: "EGP" },
  { label: "ERN", value: "ERN" },
  { label: "EEK", value: "EEK" },
  { label: "ETB", value: "ETB" },
  { label: "EUR", value: "EUR" },
  { label: "FKP", value: "FKP" },
  { label: "FJD", value: "FJD" },
  { label: "GMD", value: "GMD" },
  { label: "GEL", value: "GEL" },
  { label: "DEM", value: "DEM" },
  { label: "GHS", value: "GHS" },
  { label: "GIP", value: "GIP" },
  { label: "GRD", value: "GRD" },
  { label: "GTQ", value: "GTQ" },
  { label: "GNF", value: "GNF" },
  { label: "GYD", value: "GYD" },
  { label: "HTG", value: "HTG" },
  { label: "HNL", value: "HNL" },
  { label: "HKD", value: "HKD" },
  { label: "HUF", value: "HUF" },
  { label: "ISK", value: "ISK" },
  { label: "INR", value: "INR" },
  { label: "IDR", value: "IDR" },
  { label: "IRR", value: "IRR" },
  { label: "IQD", value: "IQD" },
  { label: "ILS", value: "ILS" },
  { label: "ITL", value: "ITL" },
  { label: "JMD", value: "JMD" },
  { label: "JPY", value: "JPY" },
  { label: "JOD", value: "JOD" },
  { label: "KZT", value: "KZT" },
  { label: "KES", value: "KES" },
  { label: "KWD", value: "KWD" },
  { label: "KGS", value: "KGS" },
  { label: "LAK", value: "LAK" },
  { label: "LVL", value: "LVL" },
  { label: "LBP", value: "LBP" },
  { label: "LSL", value: "LSL" },
  { label: "LRD", value: "LRD" },
  { label: "LYD", value: "LYD" },
  { label: "LTL", value: "LTL" },
  { label: "MOP", value: "MOP" },
  { label: "MKD", value: "MKD" },
  { label: "MGA", value: "MGA" },
  { label: "MWK", value: "MWK" },
  { label: "MYR", value: "MYR" },
  { label: "MVR", value: "MVR" },
  { label: "MRO", value: "MRO" },
  { label: "MUR", value: "MUR" },
  { label: "MXN", value: "MXN" },
  { label: "MDL", value: "MDL" },
  { label: "MNT", value: "MNT" },
  { label: "MAD", value: "MAD" },
  { label: "MZM", value: "MZM" },
  { label: "MMK", value: "MMK" },
  { label: "NAD", value: "NAD" },
  { label: "NPR", value: "NPR" },
  { label: "ANG", value: "ANG" },
  { label: "TWD", value: "TWD" },
  { label: "NZD", value: "NZD" },
  { label: "NIO", value: "NIO" },
  { label: "NGN", value: "NGN" },
  { label: "KPW", value: "KPW" },
  { label: "NOK", value: "NOK" },
  { label: "OMR", value: "OMR" },
  { label: "PKR", value: "PKR" },
  { label: "PAB", value: "PAB" },
  { label: "PGK", value: "PGK" },
  { label: "PYG", value: "PYG" },
  { label: "PEN", value: "PEN" },
  { label: "PHP", value: "PHP" },
  { label: "PLN", value: "PLN" },
  { label: "QAR", value: "QAR" },
  { label: "RON", value: "RON" },
  { label: "RUB", value: "RUB" },
  { label: "RWF", value: "RWF" },
  { label: "SVC", value: "SVC" },
  { label: "WST", value: "WST" },
  { label: "SAR", value: "SAR" },
  { label: "RSD", value: "RSD" },
  { label: "SCR", value: "SCR" },
  { label: "SLL", value: "SLL" },
  { label: "SGD", value: "SGD" },
  { label: "SKK", value: "SKK" },
  { label: "SBD", value: "SBD" },
  { label: "SOS", value: "SOS" },
  { label: "ZAR", value: "ZAR" },
  { label: "KRW", value: "KRW" },
  { label: "XDR", value: "XDR" },
  { label: "LKR", value: "LKR" },
  { label: "SHP", value: "SHP" },
  { label: "SDG", value: "SDG" },
  { label: "SRD", value: "SRD" },
  { label: "SZL", value: "SZL" },
  { label: "SEK", value: "SEK" },
  { label: "CHF", value: "CHF" },
  { label: "SYP", value: "SYP" },
  { label: "STD", value: "STD" },
  { label: "TJS", value: "TJS" },
  { label: "TZS", value: "TZS" },
  { label: "THB", value: "THB" },
  { label: "TOP", value: "TOP" },
  { label: "TTD", value: "TTD" },
  { label: "TND", value: "TND" },
  { label: "TRY", value: "TRY" },
  { label: "TMT", value: "TMT" },
  { label: "UGX", value: "UGX" },
  { label: "UAH", value: "UAH" },
  { label: "AED", value: "AED" },
  { label: "UYU", value: "UYU" },
  { label: "USD", value: "USD" },
  { label: "UZS", value: "UZS" },
  { label: "VUV", value: "VUV" },
  { label: "VEF", value: "VEF" },
  { label: "VND", value: "VND" },
  { label: "YER", value: "YER" },
  { label: "ZMK", value: "ZMK" }
];
