import styled from "styled-components";

const ReceiptWrapper = styled.div`
  .button-style {
    background: #4cbb17;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .position {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
  }
`;

export default ReceiptWrapper;
