import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";

import storeImg from "assets/images/storelogo.png";
import Api from "api/Api";
import CommonModal from "components/common/CommonModal";
import ReceiptModal from "./ReceiptModal";
import Loader from "components/common/Loader";
import Toaster from "components/common/Toaster";
import fileDownload from "js-file-download";
import moment from "moment";
import WarrantyModal from "./WarrantyModal";
import { DashboardChart } from "components/dashboard/DashboardCharts";
import { RECEIPT_TYPE } from "helper/constant";

let debounceTimer;

const ReceiptTable = ({ isStore = false, ...props }) => {
  const id = props?.match?.params?.id;
  const hasParam =
    props.location.pathname === `/user/receipt/${id}` ||
    props.location.pathname === `/store/user/receipt/${id}`
      ? true
      : false;

  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderCount, setLoaderCount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [field, setField] = useState("createdAt");
  const [viewModal, setviewModal] = useState(false);
  const [viewWarrantyModal, setViewWarrantyModal] = useState(false);
  const [viewData, setviewData] = useState(null);
  const [warrantyCards, setWarrantyCards] = useState([]);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [receiptType, setReceiptType] = useState(
    props.location.state ? props.location.state : RECEIPT_TYPE.ALL
  );
  const toaster = useRef();

  const { storeId } = props;

  const columns = [
    {
      Header: "ID",
      accessor: "receiptId",
      className: "text-center",
      filterable: false,
      sortable: false,
      headerClassName: "react-table-header-class",
      width: 100
    },
    {
      Header: "Shop Image",
      accessor: "shopImage",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      Cell: props => (
        <div>
          <img
            src={props.original.shopImage ? props.original.shopImage : storeImg}
            alt="error"
            style={{ height: "50px", width: "50px", borderRadius: "50%" }}
          />
        </div>
      )
    },
    {
      Header: "Shop name",
      accessor: "shopName",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 180
    },
    {
      Header: "Branch name",
      accessor: "branchName",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150
    },
    {
      Header: "Device Id",
      accessor: "deviceTokenId",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150
    },
    {
      Header: "Duplicate",
      accessor: "duplicate",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 120,
      Cell: props => <div>{!!props.value ? "Yes" : "No"}</div>
    },
    {
      Header: "Customer name",
      accessor: "customerName",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200
    },
    {
      Header: "Phone",
      accessor: "phone",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class"
    },
    {
      Header: "From",
      accessor: "receiptFromType",
      className: "text-center",
      sortable: false,
      filterable: false,
      width: 200,
      headerClassName: "react-table-header-class",
      Cell: props => <div>{props.value ? RECEIPT_TYPE[props.value] : "-"}</div>
    },
    {
      Header: "Warranty",
      accessor: "warrantyCards",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info  mr-10"
            onClick={() => {
              setWarrantyCards(props.value);
              setViewWarrantyModal(true);
            }}
            disabled={!props.original.warrantyCards.length}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-clipboard-check mr-6" />{" "}
              {props.original.warrantyCards.length}
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Date",
      accessor: "createdAt",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: props => (
        <div>
          {props?.original?.createdAt
            ? moment(props.original.createdAt).format("MM-DD-YYYY")
            : "-"}
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info  mr-10"
            onClick={() => viewHandler(props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-info mr-6" /> View
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    }
  }, [searchText]);

  useEffect(() => {
    setSearchText("");
    const params = {
      page: {
        limit: 10,
        pageId: 1
      },
      sortBy: {
        direction: "DESC",
        property: "createdAt"
      },
      search: "",
      receiptTypeFilter: receiptType
    };
    callListApi(params);
    setPages(10);
  }, [isStore, receiptType]);

  const callListApi = params => {
    setLoader(true);
    setLoaderCount(prev => prev + 1);
    const url = !isStore
      ? !hasParam
        ? "/admin/receiptlist"
        : `/admin/receiptlist?userId=${Number(id)}`
      : !hasParam
      ? `/admin/receiptlist?storeId=${storeId}`
      : `/admin/receiptlist?storeId=${storeId}&userId=${Number(id)}`;

    Api.post(url, params)
      .then(res => {
        settblData(res.data.data.list);
        setChartData(res.data.data.receiptType);
        setLoader(false);
        setPages(Math.ceil(res.data.data.totalRecords / 10));
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  };

  const viewHandler = data => {
    setviewData(data);
    setviewModal(true);
  };

  const downloadCSV = async () => {
    setIsCsvLoading(true);
    const response = await Api.get(
      `/admin/exportCSV/receiptList?search=${searchText}&receiptTypeFilter=${receiptType}&storeId=${
        isStore ? storeId : ""
      }&userId=${!!hasParam ? Number(id) : ""}`
    );
    const fileName = `receipts-${receiptType.toLowerCase()}-${moment().format(
      "MM-DD-YYYY"
    )}.csv`;

    if (response) {
      fileDownload(response.data, fileName);
    }
    if (response.status === 200) {
      toaster.current.success("CSV file downloaded");
    }

    setIsCsvLoading(false);
  };

  const handleReceiptClick = (el, isReceipt) => {
    if (!isReceipt) return;
    setReceiptType(el.name);
  };

  return (
    <div>
      <div className="plr-15">
        {!id && (
          <div className="flex flex-column bg-white justify-content-center h-320 pl-14 ptb-10 flex-1 border-radius-12">
            {loader && loaderCount <= 1 ? (
              <Loader loading={loader} />
            ) : (
              <div className="flex fill-height justify-content-between">
                {chartData?.filter(
                  rec => rec.name === "UNSCANNED" && rec.count !== 0
                ).length ? (
                  <div className="flex wp-40 fill-height">
                    <DashboardChart
                      data={chartData?.filter(rec => rec.name === "UNSCANNED")}
                      type="PIE"
                      isReceipt={true}
                      handleReceiptClick={handleReceiptClick}
                      outerRadius={80}
                    />
                  </div>
                ) : (
                  <div className="flex flex-1 text-color-gray text-center justify-content-center align-center">
                    No Data for Device
                  </div>
                )}
                {chartData?.filter(
                  rec => rec.name !== "UNSCANNED" && rec.count !== 0
                ).length ? (
                  <div className="flex wp-60 fill-height">
                    <DashboardChart
                      data={chartData?.filter(rec => rec.name !== "UNSCANNED")}
                      type="PIE"
                      isReceipt={true}
                      handleReceiptClick={handleReceiptClick}
                      outerRadius={80}
                    />
                  </div>
                ) : (
                  <div className="flex pa-8 text-color-gray flex-1 text-center justify-content-center align-center">
                    No Data for App Camera Upload, App Gallery Upload, Native
                    Camera Scan or APP QR Scan
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Receipt List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setLoader(true);
                  setActivePage(0);
                  callListApi({
                    page: {
                      limit: 10,
                      pageId: activePage + 1
                    },
                    search: e.target.value,
                    sortBy: {
                      direction: sortBy,
                      property: field
                    },
                    receiptTypeFilter: receiptType
                  });
                  setSearchText(e.target.value);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {!id && (
              <div className="c-btn ma-5 fs-16 demi-bold-text cursor-not-allowed">
                <>{RECEIPT_TYPE[receiptType]}</>
                {receiptType !== RECEIPT_TYPE.ALL && (
                  <i
                    className="fas fa-undo ml-1 cursor-pointer"
                    onClick={() => setReceiptType(RECEIPT_TYPE.ALL)}
                  ></i>
                )}
              </div>
            )}
            {!!tblData.length && (
              <div>
                <Button
                  className="c-btn ma-5"
                  onClick={downloadCSV}
                  style={{
                    backgroundColor: "#4CBB17",
                    color: "#fff"
                  }}
                  disabled={isCsvLoading}
                >
                  {!isCsvLoading ? (
                    <i class="fas fa-file-csv"></i>
                  ) : (
                    <i class="fas fa-ellipsis-h"></i>
                  )}
                </Button>
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              // ref={tableRef}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              getTdProps={(state, rowInfo, column) => ({
                style: {
                  height: "60px"
                }
              })}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                callListApi({
                  page: {
                    limit: 10,
                    pageId: pageIndex + 1
                  },
                  search: searchText,
                  sortBy: {
                    direction: sortBy,
                    property: field
                  },
                  receiptTypeFilter: receiptType
                });
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                callListApi({
                  page: {
                    limit: 10,
                    pageId: activePage + 1
                  },
                  search: searchText,
                  sortBy: {
                    direction: sort,
                    property:
                      sortProperties[0].id === "shopName"
                        ? "storeName"
                        : sortProperties[0].id
                  },
                  receiptTypeFilter: receiptType
                });
              }}
            />
          </div>
          <Toaster ref={toaster} />
          <CommonModal
            modal={viewModal}
            toggle={() => {
              setviewModal(!viewModal);
            }}
            size="lg"
            viewModalCheck={true}
            headerData={"Receipt Details"}
            children={<ReceiptModal data={viewData} />}
          />
          <CommonModal
            modal={viewWarrantyModal}
            toggle={() => {
              setViewWarrantyModal(prev => !prev);
            }}
            size="lg"
            viewModalCheck={true}
            headerData={"Warranty Cards"}
            children={<WarrantyModal data={warrantyCards} />}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReceiptTable);
