import React from "react";

export default function Best(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d="M20 0C8.97201 0 0 8.97201 0 20C0 31.028 8.97201 40 20 40C31.028 40 40 31.028 40 20C40 8.97201 31.0281 0 20 0ZM20 37.8378C15.1005 37.8378 10.6562 35.8518 7.42929 32.6431C6.14062 31.3618 5.04669 29.885 4.19492 28.262C2.89761 25.7905 2.16219 22.9798 2.16219 20C2.16219 10.1642 10.1642 2.16219 20 2.16219C24.6653 2.16219 28.9173 3.9633 32.0992 6.90577C33.749 8.4312 35.1113 10.2631 36.0936 12.3107C37.2112 14.6403 37.8378 17.2483 37.8378 20C37.8378 29.8358 29.8358 37.8378 20 37.8378Z"
        fill={props.fill}
      />
      <path
        d="M12.4322 21.6168L13.0767 21.1383C13.8944 20.5314 17.9728 17.3729 17.9728 14.7973C17.9728 13.4555 17.4054 12.6218 16.9292 12.1589C16.3096 11.556 15.4859 11.2241 14.6102 11.2241C13.8262 11.2241 13.0372 11.5064 12.4322 12.0587C11.8271 11.5064 11.0383 11.2241 10.2542 11.2241C9.37839 11.2241 8.55487 11.556 7.93513 12.1587C7.45918 12.6218 6.8916 13.4556 6.8916 14.7973C6.8916 17.3729 10.9702 20.5314 11.7877 21.1383L12.4322 21.6168Z"
        fill={props.fill}
      />
      <path
        d="M27.3286 21.1383L27.9731 21.6168L28.6176 21.1383C29.4353 20.5314 33.5137 17.3729 33.5137 14.7973C33.5137 13.4555 32.9463 12.6218 32.4701 12.1589C31.8505 11.556 31.0268 11.2241 30.1511 11.2241C29.367 11.2241 28.5781 11.5064 27.9731 12.0587C27.3681 11.5064 26.5792 11.2241 25.7951 11.2241C24.9193 11.2241 24.0958 11.556 23.476 12.1587C23.0001 12.6218 22.4325 13.4556 22.4325 14.7973C22.4324 17.3729 26.5109 20.5314 27.3286 21.1383Z"
        fill={props.fill}
      />
      <path
        d="M29.2004 24.5947C29.2004 24.5947 14.4016 24.5947 11.3398 24.5947C11.3398 29.5202 15.338 33.525 20.2701 33.525C25.2021 33.525 29.2004 29.5202 29.2004 24.5947ZM26.6215 26.7569H26.6528C25.7487 29.4318 23.2305 31.3628 20.2543 31.3628C17.278 31.3628 14.7602 29.4318 13.856 26.7569H26.6215Z"
        fill={props.fill}
      />
    </svg>
  );
}
