import React from "react";

export default function Good(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d="M20 0C8.97201 0 0 8.97201 0 20C0 31.028 8.97201 40 20 40C31.028 40 40 31.028 40 20C40 8.97201 31.0281 0 20 0ZM20 37.8378C15.1005 37.8378 10.6562 35.8518 7.42929 32.6431C6.14062 31.3618 5.04669 29.885 4.19492 28.262C2.89761 25.7905 2.16219 22.9796 2.16219 20C2.16219 10.1642 10.1642 2.16219 20 2.16219C24.6653 2.16219 28.9173 3.9633 32.0992 6.90577C33.749 8.4312 35.1113 10.2631 36.0936 12.3107C37.2112 14.6403 37.8378 17.2483 37.8378 20C37.8378 29.8358 29.8358 37.8378 20 37.8378Z"
        fill={props.fill}
      />
      <path
        d="M32.1617 20H7.83789C7.83789 26.7168 13.283 32.1621 19.9999 32.1621C26.7167 32.1621 32.1617 26.7168 32.1617 20ZM29.7297 22.1622H29.747C28.7561 26.6405 24.7631 29.9999 19.9914 29.9999C15.2195 29.9999 11.2263 26.6405 10.2356 22.1622H29.7297Z"
        fill={props.fill}
      />
      <path
        d="M13.3106 16.7565C14.5048 16.7565 15.4728 15.7885 15.4728 14.5943C15.4728 13.4002 14.5048 12.4321 13.3106 12.4321C12.1165 12.4321 11.1484 13.4002 11.1484 14.5943C11.1484 15.7885 12.1165 16.7565 13.3106 16.7565Z"
        fill={props.fill}
      />
      <path
        d="M26.6892 16.7565C27.8833 16.7565 28.8514 15.7885 28.8514 14.5943C28.8514 13.4002 27.8833 12.4321 26.6892 12.4321C25.495 12.4321 24.527 13.4002 24.527 14.5943C24.527 15.7885 25.495 16.7565 26.6892 16.7565Z"
        fill={props.fill}
      />
    </svg>
  );
}
